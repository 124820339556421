import { RouteRecordRaw } from '@vue-router';
import { configureRoutesMeta } from '@core/utils/configureRoutesMeta';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Auth',
    component: import('@core/layouts/AuthLayout.vue'),
    meta: {
      title: 'Аутентификация'
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: import('~/components/auth/LoginPage.vue'),
        meta: {
          title: 'Вход'
        }
      },
      {
        path: '/register',
        name: 'Register',
        component: import('~/components/auth/registration/Registration.vue'),
        meta: {
          title: 'Регистрация'
        }
      }
    ]
  },
];

export const unauthorizedRoutes = configureRoutesMeta(routes, true, 'Empty');
