import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from '@vue-router';
import { unauthorizedRoutes } from './unauthorizedRoutes';
import { authorizedRoutes } from './authorizedRoutes';
import { publicRoutes } from './publicRoutes/';


const routes: Array<RouteRecordRaw> = [...unauthorizedRoutes, ...authorizedRoutes, ...publicRoutes];

const router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),
   history: createWebHashHistory(process.env.BASE_URL),
   routes
 
});

export default router;
