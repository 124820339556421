import { RouteRecordRaw } from '@vue-router';
import { configureRoutesMeta } from '@core/utils/configureRoutesMeta'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: import('~/layouts/DefaultLayout.vue'),
  },
];

export const authorizedRoutes = configureRoutesMeta(routes, false, 'Defalt');